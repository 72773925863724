import { setContext, getContext } from 'svelte'
import { writable } from 'svelte/store'
import type { Writable } from 'svelte/store'

type NewsletterPopup = {
	open: boolean
}

const defaults: NewsletterPopup = {
	open: false
}

const NEWSLETTER_POPUP_CTX = 'NEWSLETTER_POPUP_CTX'

export function setNewsletterPopupState(data?: NewsletterPopup): Writable<NewsletterPopup> {
	const state = writable<NewsletterPopup>({
		...defaults,
		...(data && { ...data })
	})
	setContext(NEWSLETTER_POPUP_CTX, state)
	return state
}

export function getNewsletterPopupState(): Writable<NewsletterPopup> {
	return getContext<Writable<NewsletterPopup>>(NEWSLETTER_POPUP_CTX)
}
